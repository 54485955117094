/**
 * AlgoeRights 
 * AlgoeRights 
 *
 * The version of the OpenAPI document: 1.0
 * Contact: contact@tanacorp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Permission { 
    user?: string;
    path?: string;
    permission?: Permission.PermissionEnum;
}
export namespace Permission {
    export type PermissionEnum = 'RO' | 'RW' | 'N/A';
    export const PermissionEnum = {
        RO: 'RO' as PermissionEnum,
        RW: 'RW' as PermissionEnum,
        NA: 'N/A' as PermissionEnum
    };
}


