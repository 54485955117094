import { FolderModel } from './../../../models/folder.model';
import { getUsers } from './../../../store/folders.selectors';
import { Observable, Subscription } from 'rxjs';
import { AddRootFolder } from './../../../store/folders.actions';
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { TcGenericFormComponent, TcTranslateService, TcGenericEntity } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Store, select } from '@ngrx/store';
import { Folder, User } from 'src/generated/api/algoe-rights-api/model/models';
import { getNewRootFolder } from '../../../store/folders.selectors';

@Component({
  selector: 'app-folder-detail',
  templateUrl: './folder-detail.component.html',
  styleUrls: ['./folder-detail.component.scss']
})
export class FolderDetailComponent extends TcGenericFormComponent<any> implements OnInit, OnDestroy {

  newRootFolder$: Observable<Folder>;
  newRootFolder: Folder;
  newRootFolderSubscription: Subscription;



  constructor(
    private store: Store<any>,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef
  ) {
    super('Permission', entityCollectionServiceFactory, translate, elem);

    this.fields = [
      {
        key: 'code',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true,
        }
      },
    ];

  }

  ngOnInit() {
    this.newRootFolder$ = this.store.pipe(select(getNewRootFolder));
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.newRootFolderSubscription) {
      this.newRootFolderSubscription.unsubscribe();
    }
  }

  submit(model: any) {
    if (this.form.valid) {
      const folderName = (model.code as string).toUpperCase() + ' - ' + model.name;
      const folder = new FolderModel();
      folder.expanded = false;
      folder.isRootFolder = true;
      folder.level = 0;
      folder.name = folderName;
      folder.path = '\\' + folderName;
      folder.rootFolderName = folderName;
      this.store.dispatch(new AddRootFolder(folder));
    }
  }

}

