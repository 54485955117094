import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TcListComponent } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { FoldersState } from '../../../store/folders.state';
import {
  LoadFolders,
  ExpandFolder,
  CollapseFolder,
  ViewFolderPermissions,
  AddFolderInFolder
} from '../../../store/folders.actions';
import { getFolders } from '../../../store/folders.selectors';
import { FolderModel } from '../../../models/folder.model';

@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss']
})
export class FolderListComponent implements OnInit {

  constructor(
    private store: Store<FoldersState>,
  ) { }

  folderList: TcListComponent;
  @ViewChild('folderList', { static: true }) set appFolderListStore(
    values: TcListComponent
  ) {
    this.folderList = values;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<
    any
  >;

  ngOnInit() {
    this.store.dispatch(new LoadFolders('\\'));

    this.folderList.rows$ = this.store.pipe(select(getFolders));

    // this.folderList.applyClientSideFilter = (filterValue: string) => {
    //   this.folderList.dataSource.data = [];
    // };

    this.folderList.dataSource.filterPredicate = (
      data: FolderModel,
      filterValue: string
    ) =>
      !filterValue ||
      filterValue.length < 3 ||
      (filterValue.length >= 3 && data.rootFolderName.toLowerCase().includes(filterValue.toLowerCase()));

    this.folderList.hasFixedHeader = true;
    this.folderList.hasActionsLabel = false;
    this.folderList.isPaged = false;
    this.folderList.isFiltrable = true;

    this.folderList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: true,
        htmlTemplate: this.colNameTemplate
      }
    ];

    this.folderList.rowActions = [
      {
        actionName: 'permissions',
        visible: true,
        icon: 'folder_shared',
        hasText: true
      },
      {
        actionName: 'add_folder_in_folder',
        visible: true,
        icon: 'create_new_folder',
        hasText: true
      }
    ];

    this.folderList.onRowAction = (row: any, actionName: string) => {

      const folder = row as FolderModel;

      if (actionName === 'permissions') {
        this.store.dispatch(new ViewFolderPermissions(folder));
      }

      if (actionName === 'add_folder_in_folder') {
        this.store.dispatch(new AddFolderInFolder(folder));
      }
    };
  }

  loadSubfolders(row: FolderModel) {
    if (!row.expanded) {
      this.store.dispatch(new ExpandFolder(row));
    } else {
      this.store.dispatch(new CollapseFolder(row));
    }
  }
}
