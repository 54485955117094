import { Folder } from './../../../../generated/api/algoe-rights-api/model/folder';
import { Permission } from 'src/generated/api/algoe-rights-api/model/models';
export class FolderModel implements Folder {
  path: string;
  name: string;
  isRootFolder?: boolean;
  level: number;
  expanded: boolean;
  rootFolderName: string;
  subfolders: FolderModel[];
  permissions?: Permission[];
}
