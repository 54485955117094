import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/smart/login/login.component';
import { TcCoreModule } from '@tc/core';
import { AccountHeaderComponent } from './components/dumb/account-header/account-header.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

const components = [
  LoginComponent,
  AccountHeaderComponent,
];
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
  ],
  exports: [
    ...components
  ],
  entryComponents: [
  ]
})
export class AccountModule { }
