import { Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { TcListComponent } from '@tc/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FoldersState } from '../../../store/folders.state';
import { Permission } from 'src/generated/api/algoe-rights-api/model/models';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit {

  @Input()
  permissionList$: Observable<Permission[]>;

  @Output()
  deletePermission: EventEmitter<any> = new EventEmitter();

  @Output()
  editPermission: EventEmitter<any> = new EventEmitter();

  constructor() { }

  permissionList: TcListComponent;
  @ViewChild('permissionList', { static: true }) set appPermissionList(values: TcListComponent) {
    this.permissionList = values;
  }

  @ViewChild('colPermissionTemplate', { static: true }) colPermissionTemplate: TemplateRef<any>;

  ngOnInit() {

    this.permissionList.rows$ = this.permissionList$;

    this.permissionList.hasActionsLabel = false;
    this.permissionList.isPaged = false;
    this.permissionList.isFiltrable = false;

    this.permissionList.columns = [
      {
        propertyName: 'user',
        visible: true,
        filtrable: true
      },
      {
        propertyName: 'permission',
        visible: true,
        filtrable: true,
        htmlTemplate: this.colPermissionTemplate
      },

    ];


    this.permissionList.rowActions = [
      {
        actionName: 'delete',
        visible: true,
        icon: 'delete',
        hasText: true
      },
      {
        actionName: 'edit',
        visible: true,
        icon: 'edit',
        hasText: true
      }
    ];


    this.permissionList.onDeleteAction = (row: any) => {
      this.deletePermission.emit(row);
    };

    this.permissionList.onRowAction = (row: any, actionName: string) => {

      if (actionName === 'edit') {
        this.editPermission.emit(row);
      }

    };

  }

  getPermissionLabel(row: any) {
    if (row.permission && row.permission === 'N/A') {
      return '-';
    }
    return row.permission;
  }

}
