import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { User } from 'src/generated/api/algoe-rights-api';
import { Subscription, Observable } from 'rxjs';
import { getUsers, getAuditUser } from '../../../store/folders.selectors';
import { AuditUser, LoadAuditFolderList } from '../../../store/folders.actions';

@Component({
  selector: 'app-audit-detail',
  templateUrl: './audit-detail.component.html',
  styleUrls: ['./audit-detail.component.scss']
})
export class AuditDetailComponent extends TcSmartComponent implements OnInit, OnDestroy {

  users: User[];
  usersSubscription: Subscription;

  auditUser$: Observable<User>;

  constructor(private store: Store<any>) {
    super();

  }

  ngOnInit() {

    this.auditUser$ = this.store.pipe(select(getAuditUser));

    this.usersSubscription = this.store.pipe(select(getUsers)).subscribe(users => {
      this.users = users;
    });
  }

  ngOnDestroy() {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  onSelectUser(user: User) {
    // this.store.dispatch(new AuditUser(user));
    // this.store.dispatch(new LoadAuditList({ path: '\\', recursive: true, login: user.login }));
  }

  onButtonClick(user: User) {
    this.store.dispatch(new AuditUser(user));
  }

  exportExcel() {

  }
}
