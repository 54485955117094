import { Component, OnInit } from '@angular/core';
import { TcAppComponent, TcConfigService, TcTranslateService, TcNotificationService, TcAuthenticationService, TcAppState } from '@tc/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { LoadUsers } from './modules/folders/store/folders.actions';
import { VersionService } from './services/version.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TcAppComponent implements OnInit {
  appTitle = 'Algoe Rights';
  title = 'Algoe Rights';
  userName = 'Christophe	Ethoré';
  prop = 'test';
  version: string;

  constructor(
    store: Store<TcAppState>,
    configService: TcConfigService,
    authService: TcAuthenticationService,
    router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private versionService: VersionService,
    private dialog: MatDialog) {
    super(store, configService, authService, router);

  }

  ngOnInit() {
    this.version = this.versionService.getVersion();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => {
        this.title = event.title;
        if (this.title) {
          this.titleService.setTitle(this.appTitle + ': ' + this.title);
        }
      });

    this.store.dispatch(new LoadUsers());

  }
}
