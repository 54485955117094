import { initialContentState, FoldersState } from './folders.state';
import { FoldersActions, FoldersActionTypes } from './folders.actions';
import { FolderModel } from '../models/folder.model';
import { AuditModel } from '../models/audit.model';


export function foldersReducer(
  state = initialContentState,
  action: FoldersActions
): FoldersState {
  switch (action.type) {

    case FoldersActionTypes.LOAD_USERS_SUCCESS: {

      return {
        ...state,
        users: action.payload
      };
    }

    case FoldersActionTypes.LOAD_FOLDERS_SUCCESS: {

      const folders = action.payload.map(name => {

        const folder = new FolderModel();
        folder.level = 0;
        folder.expanded = false;
        folder.name = name;
        folder.path = '\\' + name;
        folder.rootFolderName = name;

        return folder;
      });

      return {
        ...state,
        newRootFolder: null,
        folders
      };
    }

    case FoldersActionTypes.EXPAND_FOLDER_SUCCESS: {

      // curent folder list from state
      const folders = state.folders;

      // new folder array
      let newFolders = [];

      // selected folder to expand
      const selectedFolder = action.payload.folder;

      // index of selected folder to expand, in the folder list from state
      const selectedFolderIndex = folders.findIndex(f => f.level === selectedFolder.level && f.name === selectedFolder.name);

      if (selectedFolderIndex >= 0) {

        // set expanded = true for the selected folder to expand
        folders[selectedFolderIndex].expanded = true;

        // construct list of subfolders for the selected folder
        // action.paylod.items = list of subfolders of the selected folder
        const subFolders = action.payload.items.map(name => {

          const subFolder = new FolderModel();
          subFolder.level = selectedFolder.level + 1;
          subFolder.expanded = false;
          subFolder.name = name;
          subFolder.path = selectedFolder.path + '\\' + name;

          // set rootFolderName (root folder name is necessary for filter)
          // if level = 0 => the selected folder to expand is a root folder => rootFolderName = selectedFolder.name
          // if level != 0 => rootFolderName = selectedFolder.rootFolderName (previous level rootFolderName)
          if (selectedFolder.level === 0) {
            subFolder.rootFolderName = selectedFolder.name;
          } else {
            subFolder.rootFolderName = selectedFolder.rootFolderName;
          }

          return subFolder;
        });


        // if folder to expand has already subFolders => expand action was dispatched after a new folder was added in the selected folder
        // we need to refresh the subFolders for the selected folder
        if (folders[selectedFolderIndex].subfolders && folders[selectedFolderIndex].subfolders.length > 0) {

          // get all subFolders for the selected folder (displayed on the screen)
          const allSubFoldersForSelectedFolder = [];
          getSubFoldersForFolder(allSubFoldersForSelectedFolder, folders[selectedFolderIndex]);

          // new subFolders array
          const newSubFolders: FolderModel[] = [];

          // iterrate all subFolders from server for the selected folder
          subFolders.forEach(subfolder => {

            // find sub folder index in the list of all subFolders for the selected folder
            const subFolderIndex = allSubFoldersForSelectedFolder.findIndex(f => f.level === subfolder.level && f.name === subfolder.name);

            // if sub folder index not found => this is the new folder added => we add this in the collection
            if (subFolderIndex < 0) {
              newSubFolders.push(subfolder);
            } else {

              // add subfolder
              newSubFolders.push(allSubFoldersForSelectedFolder[subFolderIndex]);

              // get all subfolders for the subfolder
              const allSubFoldersForSubfolder = [];
              getSubFoldersForFolder(allSubFoldersForSubfolder, allSubFoldersForSelectedFolder[subFolderIndex]);

              // iterrate all subfolders for subfolder and add this in the new subfolders array
              allSubFoldersForSubfolder.forEach(sf => {
                newSubFolders.push(sf);
              });
            }
          });

          // new folders = (keep all folders between 0 and selected folder index) + newSubFolders + (all folders between selected folder index + length of allSubfoldersForSelectedFolder and folders length)
          newFolders = folders.slice(0, selectedFolderIndex + 1).concat(newSubFolders).concat(folders.slice(selectedFolderIndex + 1 + allSubFoldersForSelectedFolder.length));
          folders[selectedFolderIndex].subfolders = newSubFolders.filter(f => f.level === selectedFolder.level + 1);

        } else { // if folder to expand has not subfolders => expand action was dispatched after a folder was selected manually

          newFolders = folders.slice(0, selectedFolderIndex + 1).concat(subFolders).concat(folders.slice(selectedFolderIndex + 1));
          folders[selectedFolderIndex].subfolders = subFolders;
        }

      }

      return {
        ...state,
        folders: newFolders
      };
    }

    case FoldersActionTypes.COLLAPSE_FOLDER: {

      // curent folder list from state
      const folders = state.folders;

      let newFolders = folders;

      // selected folder to collapse
      const selectedFolder = action.payload;

      // index of selected folder to collapse, in the folder list from state
      const selectedFolderIndex = folders.findIndex(f => f.level === selectedFolder.level && f.name === selectedFolder.name);

      if (selectedFolderIndex >= 0) {

        // set expanded = false for the selected folder to collapse
        folders[selectedFolderIndex].expanded = false;

        // get all subFolders for the selected folder to collapse
        const subFolders = [];
        getSubFoldersForFolder(subFolders, selectedFolder);

        // clear subfolders
        folders[selectedFolderIndex].subfolders = [];

        // delete all subFolders
        newFolders = deleteSubFoldersFromFolders(folders, subFolders);

      }

      return {
        ...state,
        folders: newFolders
      };
    }

    case FoldersActionTypes.ADD_ROOT_FOLDER_SUCCESS: {
      const folders = state.folders;
      folders.push(action.payload);
      return {
        ...state,
        newRootFolder: action.payload
      };
    }

    case FoldersActionTypes.CREATE_ROOT_FOLDER: {
      return {
        ...state,
        newRootFolder: null,
        folders: []
      };
    }

    case FoldersActionTypes.GET_PERMISSIONS_SUCCESS: {

      const folder = action.payload.folder;

      const userLogins = state.users.map(u => u.login.toLowerCase());

      const permissions = action.payload.permissions.filter(p => p.user && userLogins.includes(p.user.toLowerCase()));

      const folders = state.folders;

      const folderIndex = folders.findIndex(f => f.path === folder.path);

      if (folderIndex >= 0) {

        // if (!folders[folderIndex].permissions) {
        folders[folderIndex].permissions = [];
        // }
        folders[folderIndex].permissions.push(...permissions);

      }

      return {
        ...state,
        folders
      };
    }

    case FoldersActionTypes.SAVE_PERMISSION_SUCCESS: {

      const folders = state.folders;

      const path = action.payload.path;

      if (path) {
        const folderIndex = folders.findIndex(f => f.path === path);

        if (folderIndex >= 0) {

          if (!folders[folderIndex].permissions) {
            folders[folderIndex].permissions = [];
          }

          const permissionIndex = folders[folderIndex].permissions.findIndex(p => p.user === action.payload.user && p.path === action.payload.path);

          if (permissionIndex >= 0) {
            folders[folderIndex].permissions[permissionIndex].permission = action.payload.permission;
          } else {
            folders[folderIndex].permissions = folders[folderIndex].permissions.concat(action.payload);
          }

        }
      }

      return {
        ...state,
        folders
      };
    }

    case FoldersActionTypes.DELETE_PERMISSION_SUCCESS: {

      const folders = state.folders;

      action.payload.forEach(permission => {
        const folderIndex = folders.findIndex(f => f.path === permission.path);
        if (folderIndex >= 0) {
          if (folders[folderIndex].permissions && folders[folderIndex].permissions.length > 0) {
            const permissionIndex = folders[folderIndex].permissions.findIndex(p => p.path === permission.path && p.user === permission.user);
            if (permissionIndex >= 0) {
              folders[folderIndex].permissions = folders[folderIndex].permissions.slice(0, permissionIndex).concat([]).concat(folders[folderIndex].permissions.slice(permissionIndex + 1));
            }
          }
        }
      });

      return {
        ...state,
        folders
      };
    }

    case FoldersActionTypes.AUDIT_USER: {
      return {
        ...state,
        folders: [],
        newRootFolder: null,
        auditUser: action.payload
      };
    }

    case FoldersActionTypes.GET_AUDIT_PERMISSIONS_SUCCESS: {


      let auditList = state.auditList;

      const permissions = action.payload;

      const list: AuditModel[] = [];

      permissions.forEach(permission => {
        const pathPermission = permission.path;

        if (pathPermission) {
          const paths = pathPermission.split('\\');

          let level = 0;
          paths.forEach(path => {

            if (path !== '\\' && path !== '') {
              const existing = list.find(pe => pe.name === path);

              if (!existing) {

                const audit = new AuditModel();

                audit.expanded = true;
                audit.name = path;
                audit.path = '\\' + path;
                audit.level = level;
                audit.permission = permission.permission;

                list.push(audit);

              }

              level++;
            }

          });
        }
      });

      auditList = auditList.concat(list);

      return {
        ...state,
        auditList
      };

    }

    default: {
      return state;
    }

  }
}

// delete all sub folders from a list of folders
export const deleteSubFoldersFromFolders = (folders: FolderModel[], subfolders: FolderModel[]): FolderModel[] => {
  subfolders.forEach(subfolder => {
    const index = folders.findIndex(f => f.level === subfolder.level && f.name === subfolder.name);
    if (index >= 0) {
      folders = folders.slice(0, index).concat([]).concat(folders.slice(index + 1));
    }
  });

  return folders;
};

// get all sub folders for a folder
export const getSubFoldersForFolder = (subFolders: FolderModel[], folder: FolderModel) => {

  if (folder.subfolders) {

    folder.subfolders.forEach(subfolder => {

      subFolders.push(subfolder);

      getSubFoldersForFolder(subFolders, subfolder);
    });
  }
};

