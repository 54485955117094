import { TcAction } from '@tc/core';
import { FolderModel } from '../models/folder.model';
import { User, Permission } from 'src/generated/api/algoe-rights-api/model/models';

export enum FoldersActionTypes {

  LOAD_USERS = '[Folders] Load Users',
  LOAD_USERS_SUCCESS = '[Folders] Load Users Success',

  // ROOT FOLDER
  // load folders
  LOAD_FOLDERS = '[Folders] Load Folders',
  LOAD_FOLDERS_SUCCESS = '[Folders] Load Folders Success',

  // expand folders
  EXPAND_FOLDER = '[Folders] Expand Folder',
  EXPAND_FOLDER_SUCCESS = '[Folders] Expand Folder Success',

  // colapse folder
  COLLAPSE_FOLDER = '[Folders] Collapse Folder',

  // save root folder
  CREATE_ROOT_FOLDER = '[Folders] Create Root Folder',
  ADD_ROOT_FOLDER = '[Folders] Add Root Folder',
  ADD_ROOT_FOLDER_SUCCESS = '[Folders] Add Root Folder Success',


  // FOLDER
  // add new folder
  ADD_FOLDER_IN_FOLDER = '[Folders] Add Folder In Folder',
  ADD_FOLDER = '[Folders] Add Folder',
  ADD_FOLDER_SUCCESS = '[Folders] Add Folder Success',


  // PERMISSIONS
  // open view permissions for folder
  VIEW_FOLDER_PERMISSIONS = '[Folders] View Folder Permissions',

  // open modal when want to edit permissions for an user
  EDIT_USER_PERMISSION = '[Folders] Edit User Permission',

  GET_PERMISSIONS = '[Folders] Get Permissions',
  GET_PERMISSIONS_SUCCESS = '[Folders] Get Permissions Success',

  // edit/save permission for user
  SAVE_PERMISSION = '[Folders] Save Permission',
  SAVE_PERMISSION_SUCCESS = '[Folders] Save Permission Success',

  // remove permission for a user
  DELETE_PERMISSION = '[Folders] Delete Permission',
  DELETE_PERMISSION_SUCCESS = '[Folders] Delete Permission Success',

  AUDIT_USER = '[Folders] Audit User',

  LOAD_AUDIT_FOLDER_LIST = '[Folders] Load Audit Folder List',
  LOAD_AUDIT_FOLDER_LIST_SUCCESS = '[Folders] Load Audit Folder List Success',

  GET_AUDIT_PERMISSIONS = '[Folders] Get Audit Permissions',
  GET_AUDIT_PERMISSIONS_SUCCESS = '[Folders] Get Audit Permissions Success',

}

export class LoadUsers implements TcAction {
  readonly type = FoldersActionTypes.LOAD_USERS;
  constructor() { }
}

export class LoadUsersSuccess implements TcAction {
  readonly type = FoldersActionTypes.LOAD_USERS_SUCCESS;
  constructor(public payload: User[]) { }
}

export class LoadFolders implements TcAction {
  readonly type = FoldersActionTypes.LOAD_FOLDERS;
  constructor(public payload: string) { }
}

export class LoadFoldersSuccess implements TcAction {
  readonly type = FoldersActionTypes.LOAD_FOLDERS_SUCCESS;
  constructor(public payload: string[]) { }
}

export class ExpandFolder implements TcAction {
  readonly type = FoldersActionTypes.EXPAND_FOLDER;
  constructor(public payload: FolderModel) { }
}

export class ExpandFolderSuccess implements TcAction {
  readonly type = FoldersActionTypes.EXPAND_FOLDER_SUCCESS;
  constructor(public payload: { folder: FolderModel, items: string[] }) { }
}

export class CollapseFolder implements TcAction {
  readonly type = FoldersActionTypes.COLLAPSE_FOLDER;
  constructor(public payload: FolderModel) { }
}

export class CreateRootFolder implements TcAction {
  readonly type = FoldersActionTypes.CREATE_ROOT_FOLDER;
}

export class AddRootFolder implements TcAction {
  readonly type = FoldersActionTypes.ADD_ROOT_FOLDER;
  constructor(public payload: FolderModel) { }
}

export class AddRootFolderSuccess implements TcAction {
  readonly type = FoldersActionTypes.ADD_ROOT_FOLDER_SUCCESS;
  constructor(public payload: FolderModel) { }
}

export class AddFolderInFolder implements TcAction {
  readonly type = FoldersActionTypes.ADD_FOLDER_IN_FOLDER;
  constructor(public payload: FolderModel) { }
}

export class AddFolder implements TcAction {
  readonly type = FoldersActionTypes.ADD_FOLDER;
  constructor(public payload: { folder: FolderModel, parentFolder?: FolderModel }) { }
}

export class AddFolderSuccess implements TcAction {
  readonly type = FoldersActionTypes.ADD_FOLDER_SUCCESS;
  constructor(public payload: { folder: FolderModel, parentFolder?: FolderModel }) { }
}

export class ViewFolderPermissions implements TcAction {
  readonly type = FoldersActionTypes.VIEW_FOLDER_PERMISSIONS;
  constructor(public payload: FolderModel) { }
}

export class EditUserPermission implements TcAction {
  readonly type = FoldersActionTypes.EDIT_USER_PERMISSION;
  constructor(public payload: { folder: FolderModel, permission: Permission }) { }
}

export class GetPermissions implements TcAction {
  readonly type = FoldersActionTypes.GET_PERMISSIONS;
  constructor(public payload: { folder: FolderModel, recursive: boolean, user?: User }) { }
}

export class GetPermissionsSuccess implements TcAction {
  readonly type = FoldersActionTypes.GET_PERMISSIONS_SUCCESS;
  constructor(public payload: { folder: FolderModel, permissions: Permission[] }) { }
}

export class SavePermission implements TcAction {
  readonly type = FoldersActionTypes.SAVE_PERMISSION;
  constructor(public payload: Permission) { }
}

export class SavePermissionSuccess implements TcAction {
  readonly type = FoldersActionTypes.SAVE_PERMISSION_SUCCESS;
  constructor(public payload: Permission) { }
}

export class DeletePermission implements TcAction {
  readonly type = FoldersActionTypes.DELETE_PERMISSION;
  constructor(public payload: Permission) { }
}

export class DeletePermissionSuccess implements TcAction {
  readonly type = FoldersActionTypes.DELETE_PERMISSION_SUCCESS;
  constructor(public payload: Permission[]) { }
}

export class AuditUser implements TcAction {
  readonly type = FoldersActionTypes.AUDIT_USER;
  constructor(public payload: User) { }
}

export class LoadAuditFolderList implements TcAction {
  readonly type = FoldersActionTypes.LOAD_AUDIT_FOLDER_LIST;
  constructor(public payload: { path: string, user: User }) { }
}

export class LoadAuditFolderListSuccess implements TcAction {
  readonly type = FoldersActionTypes.LOAD_AUDIT_FOLDER_LIST_SUCCESS;
  constructor(public payload: { user: User, folders: string[] }) { }
}

export class GetAuditPermissions implements TcAction {
  readonly type = FoldersActionTypes.GET_AUDIT_PERMISSIONS;
  constructor(public payload: { path: string, login: string }) { }
}

export class GetAuditPermissionsSuccess implements TcAction {
  readonly type = FoldersActionTypes.GET_AUDIT_PERMISSIONS_SUCCESS;
  constructor(public payload: Permission[]) { }
}



export type FoldersActions =
  | LoadUsers
  | LoadUsersSuccess
  | LoadFolders
  | LoadFoldersSuccess
  | ExpandFolder
  | ExpandFolderSuccess
  | CollapseFolder
  | CreateRootFolder
  | AddRootFolder
  | AddRootFolderSuccess
  | AddFolder
  | AddFolderSuccess
  | AddFolderInFolder
  | ViewFolderPermissions
  | EditUserPermission
  | SavePermission
  | SavePermissionSuccess
  | GetPermissions
  | GetPermissionsSuccess
  | DeletePermission
  | DeletePermissionSuccess
  | AuditUser
  | LoadAuditFolderList
  | LoadAuditFolderListSuccess
  | GetAuditPermissions
  | GetAuditPermissionsSuccess;
