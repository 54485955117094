import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FolderModel } from '../../../models/folder.model';
import { Store } from '@ngrx/store';
import { EditUserPermission } from '../../../store/folders.actions';

@Component({
  selector: 'app-permissions-popup',
  templateUrl: './permissions-popup.component.html',
  styleUrls: ['./permissions-popup.component.scss']
})
export class PermissionsPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PermissionsPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close('ok');
  }

  // onAddUser(user: string) {
  //   this.store.dispatch(new EditUserPermission({ user, folder: this.data.folder }));
  // }

}
