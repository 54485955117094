import { TcService } from '@tc/core';
import { Injectable } from '@angular/core';
const { version } = require('../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class VersionService extends TcService {

  constructor() {
    super();
  }

  public getVersion(): string {

    return version;
  }

}
