import { FolderModel } from '../models/folder.model';
import { Folder, User } from 'src/generated/api/algoe-rights-api/model/models';
import { AuditModel } from '../models/audit.model';

export interface FoldersState {
  auditUser: User;
  newRootFolder: Folder;
  folders: FolderModel[];
  users: User[];
  auditList: AuditModel[];
}

export const initialContentState: FoldersState = {
  auditUser: null,
  newRootFolder: null,
  folders: [],
  users: [],
  auditList: []
};
