export * from './appVersion.service';
import { AppVersionService } from './appVersion.service';
export * from './folder.service';
import { FolderService } from './folder.service';
export * from './global.service';
import { GlobalService } from './global.service';
export * from './permission.service';
import { PermissionService } from './permission.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AppVersionService, FolderService, GlobalService, PermissionService, UserService];
