import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FoldersState } from './folders.state';
import { FolderModel } from '../models/folder.model';

const getFoldersState = createFeatureSelector<FoldersState>(
  'folders'
);

export const getFolders = createSelector(
  getFoldersState,
  (state: FoldersState) => state.folders
);

export const getNewRootFolder = createSelector(
  getFoldersState,
  (state: FoldersState) => state.newRootFolder
);

export const getUsers = createSelector(
  getFoldersState,
  (state: FoldersState) => state.users
);

export const getPermissionsForFolder = (folderModel: FolderModel) => createSelector(
  getFoldersState,
  (state: FoldersState) => {
    const folder = state.folders.find(f => f.path === folderModel.path);

    if (folder) {
      return folder.permissions ? folder.permissions : [];
    }

    return [];
  }
);

export const getAuditUser = createSelector(
  getFoldersState,
  (state: FoldersState) => state.auditUser
);

export const getAuditList = createSelector(
  getFoldersState,
  (state: FoldersState) => state.auditList
);

