import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FolderNamePopupComponent } from 'src/app/modules/folders/components/dumb/folder-name-popup/folder-name-popup.component';
import { TcPage } from '@tc/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { User } from 'src/generated/api/algoe-rights-api';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-audit-utilisateur-page',
  templateUrl: './audit-utilisateur-page.component.html',
  styleUrls: ['./audit-utilisateur-page.component.scss']
})
export class AuditUtilisateurPageComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }


}
