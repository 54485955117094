import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store } from '@ngrx/store';
import { CreateRootFolder } from 'src/app/modules/folders/store/folders.actions';

@Component({
  selector: 'app-choix-de-repertoire-page',
  templateUrl: './choix-de-repertoire-page.component.html',
  styleUrls: ['./choix-de-repertoire-page.component.scss']
})
export class ChoixDeRepertoirePageComponent extends TcPage implements OnInit {

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit() {
  }

  createRootFolder() {
    this.store.dispatch(new CreateRootFolder());
  }

}
