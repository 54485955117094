import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChoixDeRepertoirePageComponent } from './pages/choix-de-repertoire-page/choix-de-repertoire-page.component';
import { AuditUtilisateurPageComponent } from './pages/audit-utilisateur-page/audit-utilisateur-page.component';
import { AjouterAffairePageComponent } from './pages/ajouter-affaire-page/ajouter-affaire-page.component';

const routes: Routes = [
  {
    path: 'choix',
    data: { title: 'Choix de répertoire' },
    component: ChoixDeRepertoirePageComponent
  },
  {
    path: 'audit',
    data: { title: 'Audit utilisateur' },
    component: AuditUtilisateurPageComponent
  },
  {
    path: 'ajouter-affaire',
    data: { title: 'Ajouter affaire' },
    component: AjouterAffairePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
