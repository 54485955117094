import { Component, ElementRef, Optional, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TcGenericEntity, TcGenericFormComponent, TcTranslateService, TcFormComponent } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { SavePermission } from '../../../store/folders.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-permission-detail-popup',
  templateUrl: './permission-detail-popup.component.html',
  styleUrls: ['./permission-detail-popup.component.scss']
})
export class PermissionDetailPopupComponent extends TcFormComponent<any> implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PermissionDetailPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    translate: TcTranslateService,
    elem: ElementRef,
  ) {
    super(translate, elem);

    this.fields = [
      {
        key: 'folderName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true,
          readonly: true
        },
      },
      {
        key: 'user',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true,
          readonly: true
        }
      },
      {
        key: 'permission',
        type: 'radio',
        className: 'custom-radio',
        templateOptions: {
          // label: 'Radio',
          // placeholder: 'Placeholder',
          // description: 'Description',
          required: true,
          options: [
            { value: 'RO', label: 'Lecture Seule' },
            { value: 'RW', label: 'Lecture/Ecriture' },
            { value: 'N/A', label: 'Aucun permission' },
          ],
        },
      },
    ];
  }


  ngOnInit() {
    if (this.data) {
      this.model = Object.assign({}, this.data);
      this.model.folderPath = this.data.folder.path;
      this.model.folderName = this.data.folder.name;
      this.model.user = this.data.permission.user;
      this.model.permission = this.data.permission.permission;
    }
    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  submit(model) {
    if (this.form.valid) {

      this.dialogRef.close(model);
    }
  }

}

