import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { TcListComponent } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { EditUserPermission } from '../../../store/folders.actions';
import { getAuditList } from '../../../store/folders.selectors';
import { User } from 'src/generated/api/algoe-rights-api';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit {

  @Input()
  user: User;

  constructor(private store: Store<any>) { }

  auditList: TcListComponent;
  @ViewChild('auditList', { static: true }) set appAuditList(values: TcListComponent) {
    this.auditList = values;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;

  ngOnInit() {

    this.auditList.rows$ = this.store.pipe(select(getAuditList));

    this.auditList.hasActionsLabel = false;
    this.auditList.isPaged = false;
    this.auditList.isFiltrable = false;

    this.auditList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: true,
        htmlTemplate: this.colNameTemplate,
      },
      {
        propertyName: 'permission',
        visible: true,
        filtrable: true,
      }
    ];


    this.auditList.rowActions = [
      {
        actionName: 'edit',
        visible: true,
        icon: 'edit',
        hasText: true,
      }
    ];

    this.auditList.bulkActions = [
      {
        actionName: 'edit',
        visible: true,
        icon: 'edit',
        hasText: true,
      }
    ];

    this.auditList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.store.dispatch(new EditUserPermission(row.user));
      }
    };

  }

}
