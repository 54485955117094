import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderListComponent } from './components/smart/folder-list/folder-list.component';
import { TcCoreModule } from '@tc/core';
import { FolderNamePopupComponent } from './components/dumb/folder-name-popup/folder-name-popup.component';
import { PermissionsComponent } from './components/smart/permissions/permissions.component';
import { PermissionListComponent } from './components/dumb/permission-list/permission-list.component';
import { PermissionsPopupComponent } from './components/smart/permissions-popup/permissions-popup.component';
import { PermissionDetailPopupComponent } from './components/smart/permission-detail-popup/permission-detail-popup.component';
import { AuditDetailComponent } from './components/smart/audit-detail/audit-detail.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FoldersEffects } from './store/folders.effects';
import { foldersReducer } from './store/folders.reducer';
import { FolderDetailComponent } from './components/smart/folder-detail/folder-detail.component';
import { AutocompleteUsersComponent } from './components/dumb/autocomplete-users/autocomplete-users.component';
import { AuditListComponent } from './components/smart/audit-list/audit-list.component';


const components = [
  FolderListComponent,
  FolderNamePopupComponent,
  PermissionsComponent,
  PermissionListComponent,
  PermissionsPopupComponent,
  FolderDetailComponent,
  PermissionDetailPopupComponent,
  AuditDetailComponent,
  AuditListComponent
];
@NgModule({
  declarations: [
    ...components,
    AutocompleteUsersComponent,

  ],
  imports: [
    CommonModule,
    TcCoreModule,
    StoreModule.forFeature('folders', foldersReducer),
    EffectsModule.forFeature([
      FoldersEffects,
    ]),
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    FolderNamePopupComponent,
    PermissionsPopupComponent,
    PermissionDetailPopupComponent
  ]
})
export class FoldersModule { }
