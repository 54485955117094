import { Component, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-folder-name-popup',
  templateUrl: './folder-name-popup.component.html',
  styleUrls: ['./folder-name-popup.component.scss']
})
export class FolderNamePopupComponent extends TcFormComponent<any> {

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<FolderNamePopupComponent>,
    element: ElementRef) {
    super(translateService, element);

    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      }
    ];
  }

  onClose() {
    this.dialogRef.close();
  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(model.name);
    }
  }

}
