import { Component } from '@angular/core';
import { TcLoginFormComponent, TcAuthenticationService } from '@tc/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TcAuthState } from '@tc/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends TcLoginFormComponent {
  form: FormGroup;

  constructor(formBuilder: FormBuilder, store: Store<TcAuthState>, authService: TcAuthenticationService, router: Router) {
    super(formBuilder, store, authService, router);
  }

  register() {
    this.router.navigate(['/sign-up']);
  }
}
