import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TcCoreModule, TcApiConfig } from '@tc/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { NgrxDataModule } from 'ngrx-data';
import { LoginPageComponent } from './pages/login-page/login-page.component';

import { AccountModule } from './modules/account/account.module';
import { ChoixDeRepertoirePageComponent } from './pages/choix-de-repertoire-page/choix-de-repertoire-page.component';
import { FoldersModule } from './modules/folders/folders.module';
import { AuditUtilisateurPageComponent } from './pages/audit-utilisateur-page/audit-utilisateur-page.component';
import { AjouterAffairePageComponent } from './pages/ajouter-affaire-page/ajouter-affaire-page.component';
import { BASE_PATH } from 'src/generated/api/algoe-rights-api';
import { entityConfig } from './entity-metadata';

export function getApiBasePath(): string {
  const apiBasePath = require('../assets/config.json').API_BASE_PATH;
  return apiBasePath;
}

const apiServiceConfig: TcApiConfig = {
  // spinnerExceptions: ['api/starships/'],
  // hideToastError: false
};

// pages
const pages = [
  LoginPageComponent,
  ChoixDeRepertoirePageComponent,
  AuditUtilisateurPageComponent,
  AjouterAffairePageComponent
];

// modules
const modules = [
  // modules/account
  AccountModule,
  FoldersModule
];

@NgModule({
  declarations: [
    ...pages,
    AppComponent,
  ],
  imports: [
    ...modules,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TcCoreModule,
    MatButtonModule,
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        name: 'AlgoeRights'
      }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgrxDataModule.forRoot(entityConfig),


  ],
  providers: [
    { provide: BASE_PATH, useValue: getApiBasePath() },
    { provide: TcApiConfig, useValue: apiServiceConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
