import { EntityMetadataMap } from 'ngrx-data';

const entityMetadata: EntityMetadataMap = {
  Permission: {},
  Right: {},
  User: {}
};

const pluralNames = { };

export const entityConfig = {
  entityMetadata,
  pluralNames
};
