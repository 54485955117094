import { GetPermissions, EditUserPermission, DeletePermission } from './../../../store/folders.actions';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { FolderModel } from '../../../models/folder.model';
import { User, Permission } from 'src/generated/api/algoe-rights-api';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getUsers, getPermissionsForFolder } from '../../../store/folders.selectors';
import { PermissionListComponent } from '../../dumb/permission-list/permission-list.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit, OnDestroy {

  @Input()
  folder: FolderModel;

  permissionList$: Observable<Permission[]>;

  users: User[];
  usersSubscription: Subscription;

  appPermissionList: PermissionListComponent;
  @ViewChild('appPermissionList', { static: true }) set setAppPermissionList(values: PermissionListComponent) {
    this.appPermissionList = values;
  }

  constructor(private store: Store<any>) { }

  ngOnInit() {

    this.store.dispatch(new GetPermissions({ folder: this.folder, recursive: false }));

    this.permissionList$ = this.store.pipe(select(getPermissionsForFolder(this.folder)));

    this.usersSubscription = this.store.pipe(select(getUsers)).subscribe(users => {
      this.users = users;
    });

  }

  ngOnDestroy() {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  onSelectUser(user: User) {
    this.appPermissionList.permissionList.dataSource.filter = user && user.login ? user.login.trim().toLowerCase() : null;
  }

  onButtonClick(user: User) {
    this.store.dispatch(new EditUserPermission({ folder: this.folder, permission: { user: user.login } }));
  }

  onDeletePermission(permission: Permission) {
    this.store.dispatch(new DeletePermission({ user: permission.user, path: permission.path }));
  }

  onEditPermission(permission: Permission) {
    this.store.dispatch(new EditUserPermission({ folder: this.folder, permission }));
  }

}
