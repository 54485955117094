import { Permission } from './../../../../generated/api/algoe-rights-api/model/permission';
import { FolderModel } from './../models/folder.model';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, act } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { mergeMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FoldersState } from './folders.state';
import { AppFolderService } from 'src/app/services/app-folder.service';
import {
  FoldersActionTypes,
  LoadFolders, LoadFoldersSuccess, ExpandFolder, ExpandFolderSuccess,
  EditUserPermission, ViewFolderPermissions,
  AddFolder, AddFolderSuccess, SavePermission, SavePermissionSuccess,
  AddFolderInFolder, CreateRootFolder, AddRootFolder, AddRootFolderSuccess,
  GetPermissions, GetPermissionsSuccess, DeletePermission, DeletePermissionSuccess,
  LoadUsers, LoadUsersSuccess, LoadAuditFolderList, LoadAuditFolderListSuccess, AuditUser, GetAuditPermissions, GetAuditPermissionsSuccess,
} from './folders.actions';
import { FolderService, PermissionService, UserService, User } from 'src/generated/api/algoe-rights-api';

@Injectable()
export class FoldersEffects {

  constructor(
    private actions$: Actions,
    private appFolderService: AppFolderService,
    private userService: UserService,
    private folderService: FolderService,
    private permissionService: PermissionService
  ) { }

  @Effect()
  loadUsers$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadUsers>(
      FoldersActionTypes.LOAD_USERS
    ),
    mergeMap((action: LoadUsers) =>
      this.userService.getUsers().pipe(
        map(
          (items: User[]) =>
            new LoadUsersSuccess(items)
        )
      )
    )
  );

  @Effect()
  loadFolders$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadFolders>(
      FoldersActionTypes.LOAD_FOLDERS
    ),
    mergeMap((action: LoadFolders) =>
      this.folderService.getFolders(action.payload).pipe(
        map(
          (items: string[]) =>
            new LoadFoldersSuccess(items)
        )
      )
    )
  );

  @Effect()
  expandFolder$: Observable<TcAction> = this.actions$.pipe(
    ofType<ExpandFolder>(
      FoldersActionTypes.EXPAND_FOLDER
    ),
    mergeMap((action: ExpandFolder) =>
      this.folderService.getFolders(action.payload.path).pipe(
        map(
          (items: string[]) =>
            new ExpandFolderSuccess({ folder: action.payload, items })
        )
      )
    )
  );

  @Effect({ dispatch: false })
  addFolderInFolder$ = this.actions$.pipe(
    ofType<AddFolderInFolder>(
      FoldersActionTypes.ADD_FOLDER_IN_FOLDER
    ),
    map((action: AddFolderInFolder) => {
      this.appFolderService.addFolderInFolder(action.payload);
    })
  );

  @Effect()
  addFolder$: Observable<TcAction> = this.actions$.pipe(
    ofType<AddFolder>(
      FoldersActionTypes.ADD_FOLDER
    ),
    mergeMap((action: AddFolder) =>
      this.folderService.addFolder(action.payload.folder).pipe(
        map(
          (folder: FolderModel) =>
            new AddFolderSuccess({ folder, parentFolder: action.payload.parentFolder })
        )
      )
    )
  );

  @Effect({ dispatch: false })
  addFolderSuccess = this.actions$.pipe(
    ofType<AddFolderSuccess>(FoldersActionTypes.ADD_FOLDER_SUCCESS),
    map((action: AddFolderSuccess) => {
      this.appFolderService.addFolderSuccess(action.payload.folder, action.payload.parentFolder);
    })
  );

  @Effect({ dispatch: false })
  createRootFolder = this.actions$.pipe(
    ofType<CreateRootFolder>(FoldersActionTypes.CREATE_ROOT_FOLDER),
    map((action: CreateRootFolder) => {
      this.appFolderService.createRootFolder();
    })
  );

  @Effect()
  addRootFolder$: Observable<TcAction> = this.actions$.pipe(
    ofType<AddRootFolder>(
      FoldersActionTypes.ADD_ROOT_FOLDER
    ),
    mergeMap((action: AddRootFolder) =>
      this.folderService.addRootFolder(action.payload).pipe(
        map(
          (folder: FolderModel) =>
            new AddRootFolderSuccess(folder)
        )
      )
    )
  );

  @Effect({ dispatch: false })
  addRootFolderSuccess = this.actions$.pipe(
    ofType<AddRootFolderSuccess>(FoldersActionTypes.ADD_ROOT_FOLDER_SUCCESS),
    map((action: AddRootFolderSuccess) => {
      this.appFolderService.addRootFolderSuccess(action.payload);
    })
  );


  @Effect({ dispatch: false })
  viewFolderPermissions = this.actions$.pipe(
    ofType<ViewFolderPermissions>(FoldersActionTypes.VIEW_FOLDER_PERMISSIONS),
    map((action: ViewFolderPermissions) => {
      this.appFolderService.viewFolderPermissions(action.payload);
    })
  );

  @Effect({ dispatch: false })
  openEditUserPermissionModal = this.actions$.pipe(
    ofType<EditUserPermission>(FoldersActionTypes.EDIT_USER_PERMISSION),
    map((action: EditUserPermission) => {
      this.appFolderService.openEditUserPermissionModal(action.payload.folder, action.payload.permission);
    })
  );

  @Effect()
  getPermissions$: Observable<TcAction> = this.actions$.pipe(
    ofType<GetPermissions>(
      FoldersActionTypes.GET_PERMISSIONS
    ),
    mergeMap((action: GetPermissions) =>
      this.permissionService.getPermissions(action.payload.folder.path, action.payload.recursive, action.payload.user ? action.payload.user.login : null).pipe(
        map(
          (permissions: any[]) =>
            new GetPermissionsSuccess({ folder: action.payload.folder, permissions })
        )
      )
    )
  );

  @Effect()
  savePermission$: Observable<TcAction> = this.actions$.pipe(
    ofType<SavePermission>(
      FoldersActionTypes.SAVE_PERMISSION
    ),
    mergeMap((action: SavePermission) =>
      this.permissionService.savePermission(action.payload).pipe(
        map(
          (item: Permission) =>
            new SavePermissionSuccess(item)
        )
      )
    )
  );

  @Effect()
  deletePermission$: Observable<TcAction> = this.actions$.pipe(
    ofType<DeletePermission>(
      FoldersActionTypes.DELETE_PERMISSION
    ),
    mergeMap((action: DeletePermission) =>
      this.permissionService.deletePermission(action.payload.path, action.payload.user).pipe(
        map(
          (items: Permission[]) =>
            new DeletePermissionSuccess(items)
        )
      )
    )
  );

  @Effect({ dispatch: false })
  auditUser = this.actions$.pipe(
    ofType<AuditUser>(FoldersActionTypes.AUDIT_USER),
    map((action: AuditUser) => {
      this.appFolderService.loadAuditFolderList(action.payload);
    })
  );


  @Effect()
  loadAuditFolderList$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadAuditFolderList>(
      FoldersActionTypes.LOAD_AUDIT_FOLDER_LIST
    ),
    mergeMap((action: LoadAuditFolderList) =>
      this.folderService.getFolders(action.payload.path).pipe(
        map(
          (folders: any[]) =>
            new LoadAuditFolderListSuccess({ user: action.payload.user, folders })
        )
      )
    )
  );

  @Effect({ dispatch: false })
  loadAuditFolderListSuccess = this.actions$.pipe(
    ofType<LoadAuditFolderListSuccess>(FoldersActionTypes.LOAD_AUDIT_FOLDER_LIST_SUCCESS),
    map((action: LoadAuditFolderListSuccess) => {
      this.appFolderService.loadAuditFolderListSuccess(action.payload.user, action.payload.folders);
    })
  );

  @Effect()
  getAuditPermissions$: Observable<TcAction> = this.actions$.pipe(
    ofType<GetAuditPermissions>(
      FoldersActionTypes.GET_AUDIT_PERMISSIONS
    ),
    mergeMap((action: GetAuditPermissions) =>
      this.permissionService.getPermissions(action.payload.path, true, action.payload.login).pipe(
        map(
          (permissions: any[]) =>
            new GetAuditPermissionsSuccess(permissions)
        )
      )
    )
  );

}
