import { User } from './../../../../../../generated/api/algoe-rights-api/model/user';
import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';

@Component({
  selector: 'app-autocomplete-users',
  templateUrl: './autocomplete-users.component.html',
  styleUrls: ['./autocomplete-users.component.scss']
})
export class AutocompleteUsersComponent implements OnInit {

  @Input()
  users: User[];

  @Input()
  selectText: string;

  @Output()
  selectUser: EventEmitter<any> = new EventEmitter();

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter();

  form = new FormControl();
  filteredOptions: Observable<User[]>;

  constructor() { }

  ngOnInit() {
    // const names = this.users.map(item => {
    //   const key = 'nom';
    //   return item[key];
    // });
    this.filteredOptions = this.form.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value, this.users))
    );
  }

  private _filter(value: string, users: User[]): User[] {
    if (value !== '' && value.length >= 3) {
      const filterValue = value.toLowerCase();
      return users.filter(option => option.login.toLowerCase().includes(filterValue));
    }

  }

  onButtonClicked() {
    if (this.form.value) {
      this.buttonClick.emit(this.form.value);
    }
  }

  selectionChange(event: MatOptionSelectionChange, option: any) {
    if (event.source.selected) {
      this.selectUser.emit(option);
    }
  }

  displayFn(user: User): string | undefined {
    if (user) {
      const item = this.users.find(x => x.login === user.login);
      if (item) {
        return item.login;

      }
    }
    return null;
  }

  handleEmptyInput(event: any) {
    if (event.target.value === '') {
      this.selectUser.emit(null);
    }
  }

}
