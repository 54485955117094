import { SavePermission, DeletePermission, LoadAuditFolderList, GetAuditPermissions } from './../modules/folders/store/folders.actions';
import { Injectable } from '@angular/core';
import { TcNotificationService, TcTranslateService, TcService } from '@tc/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FolderNamePopupComponent } from '../modules/folders/components/dumb/folder-name-popup/folder-name-popup.component';
import { PermissionsPopupComponent } from '../modules/folders/components/smart/permissions-popup/permissions-popup.component';
// tslint:disable-next-line:max-line-length
import { PermissionDetailPopupComponent } from '../modules/folders/components/smart/permission-detail-popup/permission-detail-popup.component';
import { FolderModel } from '../modules/folders/models/folder.model';
import { Store } from '@ngrx/store';
import { AddFolder, ExpandFolder } from '../modules/folders/store/folders.actions';
import { Folder, Permission, User } from 'src/generated/api/algoe-rights-api/model/models';

@Injectable({
  providedIn: 'root'
})
export class AppFolderService extends TcService {
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService,
    private router: Router) {
    super();
  }

  addFolderInFolder(parentFolder: FolderModel) {
    const folderNameDialog = this.dialog.open(FolderNamePopupComponent, {
      width: '320px'
    });

    folderNameDialog.afterClosed().subscribe(newFolderName => {
      if (newFolderName) {
        const folder = {} as FolderModel;
        folder.path = parentFolder.path + '\\' + newFolderName;
        folder.name = newFolderName;
        this.store.dispatch(new AddFolder({ folder, parentFolder }));
      }
    });
  }

  addFolderSuccess(folder: Folder, parentFolder?: FolderModel) {
    if (parentFolder) {
      this.store.dispatch(new ExpandFolder(parentFolder));
      this.notificationService.success(folder.name + ' a été ajouté avec succès au ' + parentFolder.name + '!');
    }
  }

  createRootFolder() {
    this.router.navigate(['/ajouter-affaire']);
  }

  addRootFolderSuccess(folder: Folder) {
    this.notificationService.success(folder.name + ' a été ajouté avec succès!');
  }


  viewFolderPermissions(folder: FolderModel) {
    this.dialog.open(PermissionsPopupComponent, {
      width: '760px',
      data: folder
    });
  }

  openEditUserPermissionModal(folder: FolderModel, permission: Permission) {
    const permissionDialog = this.dialog.open(PermissionDetailPopupComponent, {
      width: '600px',
      data: { folder, permission }
    });

    permissionDialog.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new SavePermission({ user: result.user, permission: result.permission, path: result.folderPath }));
      }
    });
  }

  loadAuditFolderList(user: User) {
    this.store.dispatch(new LoadAuditFolderList({ path: '\\', user }));
  }

  loadAuditFolderListSuccess(user: User, rootFolders: string[]) {
    rootFolders.slice(0, 2).forEach(rootFolder => {
      this.store.dispatch(new GetAuditPermissions({ path: '\\' + rootFolder, login: user.login }));
    });
  }
}
